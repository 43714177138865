<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="700px">
      <v-card>
        <v-card-title>รายการเอกสารที่ต้องการกู้คืน <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">restore</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="overflow-x:hidden; overflow-y:hidden;" class="ml-n2">
          <br />
           <div class="text-center"><v-chip class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme"> folder </v-icon><b> โฟลเดอร์ที่เลือก {{ count_folder }} โฟลเดอร์</b></p></v-chip>
           <v-chip class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme">mdi-file</v-icon><b> ไฟล์ที่เลือก {{ count_file }} ไฟล์</b></p></v-chip>
           <p v-if="check_restore === true">กำลังกู้คืน {{count_restoring}} / {{countshowrestore}} รายการ</p>
          <!-- <v-chip class="pt-4 pl-2 ma-2"><p ><v-icon color="green"> mdi-delete</v-icon><b> มีสิทธิ์ลบ {{ countshowdelete }} ไฟล์</b></p></v-chip> -->
          <!-- <v-chip class="pt-4 pl-2 ma-2"><p ><v-icon color="red"> mdi-delete-off </v-icon><b> {{ countshowcannotdelete }} {{$t('dialogmultidelete.title2')}}</b></p></v-chip> -->
          </div> 
          <div class="scroll" style="overflow-x:hidden; overflow-y:hidden;">
          <v-list>
            <!--โชว์ไฟล์สามารถลบได้ -->
            <v-list-item v-for="item in showrestore" :key="item.title">
              <v-list-item-action @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                <v-icon large color="primary">{{ item.file_type === "folder" ? "folder" : "mdi-file " }}</v-icon>
              </v-list-item-action>
              <v-badge
                :value="item.show_name"
                color="grey"
                :content="item.file_name"
                
                transition="slide-y-transition"
              ></v-badge>
              <v-list-item-content>
                 
                <v-list-item-title @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                  {{ item.file_name }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  <v-progress-linear
                    v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                    :value="item.file_value"
                    color="light-blue"
                  ></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- โชว์ว่ามีสิทธิ์ลบหรือไม่-->
              <!-- <v-list-item-action v-if="check_show_progress === false">
                <v-chip
                  class="ma-2 font-weight-bold"
                  :color="item.permission_delete === true ? 'light-blue' : 'red'"
                  label
                  outlined
                >
                 {{ item.permission_delete === true ? $t('dialogmultidelete.canbedelete') : $t('dialogmultidelete.cannotdelete') }}
                </v-chip>
              </v-list-item-action> -->
              <!-- โชว์สถานะการลบว่าลบสำเร็จหรือไม่ -->
              <v-list-item-action >
                <v-chip v-if="item.file_value !== 500" class="ma-2 font-weight-bold" :color="item.file_status === 'ต้องการกู้คืน' || item.file_status === 'กำลังกู้คืน' ? color.theme : item.file_status === 'กู้คืนไฟล์สำเร็จ' ? 'green' : item.file_status === 'ไม่สามารถกู้คืนไฟล์นี้ได้' ? 'red' : '#F37958' " label outlined>
                  {{ item.file_status }}
                </v-chip>
                <v-chip v-else class="ma-2 font-weight-bold" color="red" label outlined>
                  {{ item.file_status }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- ปุ่มก่อนกดยืนยันที่จะลบหรือกดปิดdialog -->
          <v-btn
            v-if="check_show_progress === false && check_show_button === false"
            color="red"
            outlined
            @click="$emit('closedialog'),$emit('closebuttonmulti')"
            >{{ $t("changeName.close") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            v-if="check_show_progress === false && check_show_button === false"
            :dark="color.darkTheme"
            @click="fn_multirestore(), (check_show_button = true), (check_show_progress = true), (check_restore =true)"
            >กู้คืน</v-btn
          >
          <!-- ลบเสร็จจะโชว์ปุ่มนี้เพื่อกดปิด-->
          <v-btn color="red" :disabled="check_dis_button" v-if="check_show_button === true" outlined @click="$emit('closedialogreload'),$emit('closebuttonmulti'),(check_restore = false)">{{$t('dialogmultidelete.buttonclose')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
  },
  props: ["show", "file",],
  data: function() {
    return {
      check_restore:false,
      check_dis_button:true,
      check_show_progress: false,
      check_show_button: false,
      showrestore: [],
      // showcannotdelete:[],
      count_item: 0,
      count_folder:0,
      count_file:0,
      list_folder:[],
      list_file:[],
      countshowrestore:0,
      count_restoring:0,
      // countshowcannotdelete:0

    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          console.log("file",this.file);
        }
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      },
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
        this.$emit("closeDrag");
        this.fn_loaddata();
        this.check_show_button = false;
        console.log(this.file);
      } else {
        this.$emit("openDrag"); 
        this.check_show_progress = false;
        this.showrestore = [];
        this.showcannotdelete = [];
        this.count_item = 0;
        this.count_folder = 0;
        this.count_file = 0;
      }
    },
  },
  methods: {
    fn_loaddata() {

      for (let i = 0; i < this.file.length; i++) {
        this.count_item++;
        console.log(this.file);
        if(this.file[i].file_type === 'folder'){
          this.count_folder++;
          // this.list_folder.push(this.file[i])
          // this.count_folder = this.list_folder.length;
        }else{
          this.count_file++;
          // this.list_file.push(this.file[i])
          // this.count_file = this.list_file.length;
        }
        if(this.$router.app["_route"]["name"] === "trashinbox" || this.$router.app["_route"]["name"] === "trashoutbox"){
        console.log("++++",this.file[i]);
        let datarestore = {};
        datarestore["file_name"] = this.file[i]["file_name"];
        datarestore["file_id"] = this.file[i]["file_id"];
        datarestore["file_icon"] = this.file[i]["file_icon"];
        datarestore["file_status"] = 'มีสิทธิ์กู้คืนไฟล์';
        // datarestore["file_status"] = "ต้องการกู้คืน";
        datarestore["file_value"] = 0;
        datarestore["show_name"] = false;
        datarestore["file_type"] = this.file[i]["file_type"];
          this.showrestore.push(datarestore);
        }else{
          console.log("++++",this.file[i]);
        let datarestore = {};
        datarestore["file_name"] = this.file[i]["file_name"];
        datarestore["file_id"] = this.file[i]["file_id"];
        datarestore["file_icon"] = this.file[i]["file_icon"];
        datarestore["file_status"] = this.file[i]["permission_account_v2"] === '05' || this.file[i]["permission_account_v2"] === '06' ? 'มีสิทธิ์กู้คืนไฟล์' : 'ไม่มีสิทธิ์กู้คืนไฟล์' ;
        // datarestore["file_status"] = "ต้องการกู้คืน";
        datarestore["file_value"] = 0;
        datarestore["show_name"] = false;
        datarestore["file_type"] = this.file[i]["file_type"];
          this.showrestore.push(datarestore);
        }
      }
      this.countshowrestore = this.showrestore.length;
      this.count_restoring = 0;
      // console.log("showrestore",this.showrestore);
    },
    //ฟังก์ชั่นวนกู้คืนไฟล์
    async fn_multirestore() {
      if(this.$router.app["_route"]["name"] === "trashinbox"){
        for (let i = 0; i < this.file.length; i++) {
          let objIndex = this.showrestore.findIndex((obj) => obj.file_id === this.file[i].file_id);
          //เช็คเพื่อนวนส่งข้อมูล
            this.showrestore[objIndex]["file_status"] = "กำลังกู้คืน";
            // let payload = {
            //   file_id:[this.file[i]["file_id"]],
            //   account_id:this.dataAccountId,
            //   status:"trash_inbox"
            // };
            let payload = {
                business_id : this.dataAccountActive.business_info.business_id,
                data_type : "inbox",
                inbox_id: [this.file[i]["inbox_id"]]
            };
            // console.log("payload",payload);
            await this.fn_restore(payload, this.file[i].file_id).then(async (res) => {
              if(res.status === "recover success"){
                this.count_restoring++;
                console.log("yess");
              }else{
                console.log("nooo");
              }
            });
          if(this.file.length - i === 1){
          this.check_dis_button = false;
        }
         this.scrollToBottom();
        }
      }else if(this.$router.app["_route"]["name"] === "trashoutbox"){
        for (let i = 0; i < this.file.length; i++) {
          let objIndex = this.showrestore.findIndex((obj) => obj.file_id === this.file[i].file_id);
          //เช็คเพื่อนวนส่งข้อมูล
            this.showrestore[objIndex]["file_status"] = "กำลังกู้คืน";
            // let payload = {
            //   file_id:[this.file[i]["file_id"]],
            //   account_id:this.dataAccountId,
            //   status:"trash_outbox"
            // };
            let payload = {
                business_id : this.dataAccountActive.business_info.business_id,
                data_type : "outbox",
                inbox_id: [this.file[i]["inbox_id"]]
            };
            // console.log("payload",payload);
            // console.log("file----",this.file[i].file_id);
            await this.fn_restore(payload, this.file[i].file_id)
            .then(async (res) => {
              console.log("res++++",res);
              if(res.status === "recover success"){
                this.count_restoring++;
                console.log("yess");
              }else{
                console.log("nooo");
              }
            });
              if(this.file.length - i === 1){
              this.check_dis_button = false;
        }
         this.scrollToBottom();
        }
      }else{
        for (let i = 0; i < this.file.length; i++) {
          let objIndex = this.showrestore.findIndex((obj) => obj.file_id === this.file[i].file_id);
          //เช็คเพื่อนวนส่งข้อมูล
          if(this.file[objIndex].permission_account_v2 === '05' || this.file[objIndex].permission_account_v2 === '06'){
            this.showrestore[objIndex]["file_status"] = "กำลังกู้คืน";
            let payload = {
              account_id: this.file[i]["account_sender"],
              user_id: this.dataUsername,
              data_id: this.file[i]["file_id"],
              data_type: this.file[i]["file_type"] === "folder" ? "folder" : "file",
            };
            // console.log("payload",payload);
            // console.log("file----",this.file[i].file_id);
            await this.fn_restore(payload, this.file[i].file_id)
            .then(async (res) => {
              // console.log("res++++",res);
              if(res.status === "recover success"){
                this.count_restoring++;
                console.log("yess");
              }else{
                console.log("nooo");
              }
            });
          }else{
             this.showrestore[objIndex]["file_status"] = "ไม่มีสิทธิ์กู้คืนไฟล์";
             this.showrestore[objIndex]["file_value"] = 100;
          }
              if(this.file.length - i === 1){
              this.check_dis_button = false;
        }
         this.scrollToBottom();
        }
      }
     
    },
    scrollToBottom() {
      var containers = document.querySelector(".scroll");
      var scrollHeight = containers.scrollHeight;
      containers.scrollTop = containers.scrollTop + 70
    },
    //กู้คืนไฟล์/โฟลเดอร์
    async fn_restore(payload, file_id) {
      console.log("fn_restore file_id",file_id);
      if(this.$router.app["_route"]["name"] === "trashinbox"){
        console.log("in");
        let objIndex = this.showrestore.findIndex((obj) => obj.file_id === file_id);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          // url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/recover_inbox_outbox",
          url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/recovery_file_inbox_outbox",
          data: payload,
         headers: { Authorization: auth.code },

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showrestore[objIndex]["file_value"] = 95;
            } else {
              this.showrestore[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showrestore[objIndex]["file_status"] = "กู้คืนไฟล์สำเร็จ";
              this.showrestore[objIndex]["file_value"] = 100;
              setTimeout(() => {
                    resolve({ status: "recover success", msg: "OK", code: "" });
                      }, 500);
              
            } else {
              this.showrestore[objIndex]["file_status"] = "ไม่สามารถกู้คืนไฟล์นี้ได้";
              this.showrestore[objIndex]["file_value"] = 500;
              setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": ", code: "" });
                      }, 500);
              
            }
          } catch (err) {
            setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": " + err, code: "" });
                      }, 500);
            this.showrestore[objIndex]["file_status"] = "ไม่สามารถกู้คืนไฟล์นี้ได้";
            this.showrestore[objIndex]["file_value"] = 500;
          }
        });
      }else if(this.$router.app["_route"]["name"] === "trashoutbox"){
        console.log("out");
        let objIndex = this.showrestore.findIndex((obj) => obj.file_id === file_id);
        console.log("objIndex",objIndex);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          // url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/recover_inbox_outbox",
          url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/recovery_file_inbox_outbox",
          data: payload,
           headers: { Authorization: auth.code },


          onUploadProgress: (e) => {
            console.log(e);
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showrestore[objIndex]["file_value"] = 95;
            } else {
              this.showrestore[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showrestore[objIndex]["file_status"] = "กู้คืนไฟล์สำเร็จ";
              this.showrestore[objIndex]["file_value"] = 100;
              setTimeout(() => {
                    resolve({ status: "recover success", msg: "OK", code: "" });
                      }, 500);
              
            } else {
              this.showrestore[objIndex]["file_status"] = "ไม่สามารถกู้คืนไฟล์นี้ได้";
              this.showrestore[objIndex]["file_value"] = 500;
              setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": ", code: "" });
                      }, 500);
              
            }
          } 
          catch (err) {
            this.showrestore[objIndex]["file_status"] = "ไม่สามารถกู้คืนไฟล์นี้ได้";
            this.showrestore[objIndex]["file_value"] = 500;
            setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": " + err, code: "" });
                      }, 500);
          }
        });
      }else{
         console.log("in");
        let objIndex = this.showrestore.findIndex((obj) => obj.file_id === file_id);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/recovery_file_folder_from_trash",
          data: payload,
          headers: { Authorization: auth.code },

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showrestore[objIndex]["file_value"] = 95;
            } else {
              this.showrestore[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showrestore[objIndex]["file_status"] = "กู้คืนไฟล์สำเร็จ";
              this.showrestore[objIndex]["file_value"] = 100;
              setTimeout(() => {
                    resolve({ status: "recover success", msg: "OK", code: "" });
                      }, 500);
              
            } else {
              this.showrestore[objIndex]["file_status"] = "ไม่สามารถกู้คืนไฟล์นี้ได้";
              this.showrestore[objIndex]["file_value"] = 500;
              setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": ", code: "" });
                      }, 500);
              
            }
          } catch (err) {
            setTimeout(() => {
                    resolve({ status: "recover denied", msg: ": " + err, code: "" });
                      }, 500);
            this.showrestore[objIndex]["file_status"] = "ไม่สามารถกู้คืนไฟล์นี้ได้";
            this.showrestore[objIndex]["file_value"] = 500;
          }
        });
      }
    },
  },
};
</script>
<style scoped>
 .scroll{
	width: 670px;
	max-height: 500px;
	overflow: scroll;
	background: lightgrey;
	margin-bottom: 20px;
}
</style>